$radius-circle: 100% !default;
$radius-default: 3px !default;
$radius-large: 8px !default;
$radius-medium: 4px !default;
$radius-pill: 9999px !default;
$radius-small: 2px !default;
$site-margins: 3rem !default;
$site-margins-mobile: 1.5rem !default;
$site-max-width: 1104px !default;
$media-width-xs: 0px !default;
$media-width-sm: 544px !default;
$media-width-md: 768px !default;
$media-width-lg: 1024px !default;
$media-width-xl: 1280px !default;
$measure-narrow: 45ex !default;
$measure-base: 65ex !default;
$measure-wide: 80ex !default;
$spacer-1: 8px !default;
$spacer-2: 16px !default;
$spacer-3: 24px !default;
$spacer-4: 32px !default;
$spacer-5: 40px !default;
$spacer-6: 48px !default;
$spacer-7: 56px !default;
$spacer-none: 0px !default;
$spacer-half: 4px !default;
$grid-columns: 12 !default;
$grid-gutter-width: $spacer-4 !default;
$grid-form-gutter-width: $spacer-2 !default;
$animation-speed-1: 250ms !default;
$animation-speed-2: 300ms !default;
$animation-speed-3: 500ms !default;
$animation-speed-4: 800ms !default;
$field-max-width: 460px !default;
$field-max-width--small: 6rem !default;
$field-max-width--medium: 12rem !default;
$color-white: #ffffff !default;
$color-black: #000000 !default;
$color-transparent: transparent !default;
$color-transparent-black-alpha50: #00000080 !default;
$color-transparent-black-alpha25: #00000040 !default;
$color-transparent-white-alpha50: #ffffff80 !default;
$color-transparent-white-alpha25: #ffffff40 !default;
$color-background: #ffffff !default;
$color-background-inverse: #00395e !default;
$color-base: #262626 !default;
$color-base-inverse: #ffffff !default;
$color-border: #d9d9d9 !default;
$color-border-dark: #0f1e38 !default;
$color-border-inverse: #ffffff !default;
$color-error-lightest: #fef5f7 !default;
$color-error-lighter: #f7bbc5 !default;
$color-error-light: #eb6077 !default;
$color-error: #e31c3d !default;
$color-error-dark: #cc1937 !default;
$color-error-darker: #9f142b !default;
$color-error-darkest: #720e1f !default;
$color-focus-light: #ffffff !default;
$color-focus-dark: #bd13b8 !default;
$color-gray-lightest: #f2f2f2 !default;
$color-gray-lighter: #d9d9d9 !default;
$color-gray-light: #a6a6a6 !default;
$color-gray: #808080 !default;
$color-gray-dark: #5a5a5a !default;
$color-gray-darker: #404040 !default;
$color-gray-darkest: #262626 !default;
$color-muted: #5a5a5a !default;
$color-muted-inverse: #e9ecf1 !default;
$color-primary-lightest: #e6f1f8 !default;
$color-primary-lighter: #b3d4eb !default;
$color-primary-light: #4d9cd0 !default;
$color-primary: #0071bc !default;
$color-primary-dark: #0066a9 !default;
$color-primary-darker: #004f84 !default;
$color-primary-darkest: #00395e !default;
$color-secondary-lightest: #e7f3e7 !default;
$color-secondary-lighter: #b8dcb7 !default;
$color-secondary-light: #59ac56 !default;
$color-secondary: #12890e !default;
$color-secondary-dark: #107b0d !default;
$color-secondary-darker: #0d600a !default;
$color-secondary-darkest: #094507 !default;
$color-accent-primary-lightest: #fcebe6 !default;
$color-accent-primary-lighter: #f5c3b3 !default;
$color-accent-primary-light: #e7724f !default;
$color-accent-primary: #dd3603 !default;
$color-accent-primary-dark: #c73103 !default;
$color-accent-primary-darker: #9b2602 !default;
$color-accent-primary-darkest: #6f1b02 !default;
$color-info-lightest: #e6f9fd !default;
$color-info-lighter: #b3ecf8 !default;
$color-info-light: #4ed2ee !default;
$color-info: #02bfe7 !default;
$color-info-dark: #02acd0 !default;
$color-info-darker: #0186a2 !default;
$color-info-darkest: #016074 !default;
$color-success-lightest: #f6faf5 !default;
$color-success-lighter: #b8dcb7 !default;
$color-success-light: #59ac56 !default;
$color-success: #12890e !default;
$color-success-dark: #107b0d !default;
$color-success-darker: #0d600a !default;
$color-success-darkest: #094507 !default;
$color-warn-lightest: #fef9e9 !default;
$color-warn-lighter: #fdedbc !default;
$color-warn-light: #fad662 !default;
$color-warn: #f8c41f !default;
$color-warn-dark: #dfb01c !default;
$color-warn-darker: #ae8916 !default;
$color-warn-darkest: #7c6210 !default;
$color-visited: #4c2c92 !default;
$font-size-base: 1rem !default;
$font-size-sm: 0.875rem !default;
$font-size-md: 1rem !default;
$font-size-lg: 1.125rem !default;
$font-size-xl: 1.3125rem !default;
$font-size-2xl--mobile: 1.5rem !default;
$font-size-2xl: 1.5rem !default;
$font-size-3xl--mobile: 1.875rem !default;
$font-size-3xl: 2.25rem !default;
$font-size-4xl--mobile: 2.25rem !default;
$font-size-4xl: 3rem !default;
$font-size-5xl--mobile: 2.25rem !default;
$font-size-5xl--tablet: 3rem !default;
$font-size-5xl: 3.75rem !default;
$font-line-height-reset: 1 !default;
$font-line-height-heading: 1.2999999523162842 !default;
$font-line-height-base: 1.5 !default;
$font-line-height-lead: 1.7000000476837158 !default;
$font-family-heading: 'Open Sans', 'Inter', 'Roboto', 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', 'Arial', 'sans-serif' !default;
$font-family-body: 'Open Sans', 'Inter', 'Roboto', 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', 'Arial', 'sans-serif' !default;
$font-family-button: 'Open Sans', 'Inter', 'Roboto', 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', 'Arial', 'sans-serif' !default;
$font-family-link: 'Open Sans', 'Inter', 'Roboto', 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', 'Arial', 'sans-serif' !default;
$font-family-pagination: 'Open Sans', 'Inter', 'Roboto', 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', 'Arial', 'sans-serif' !default;
$font-weight-normal: 400 !default;
$font-weight-button-sm: $font-weight-normal !default;
$font-weight-body-sm: $font-weight-normal !default;
$font-weight-body-md: $font-weight-normal !default;
$font-weight-body-lg: $font-weight-normal !default;
$font-weight-heading-sm: $font-weight-normal !default;
$font-weight-heading-3xl: $font-weight-normal !default;
$font-weight-heading-4xl: $font-weight-normal !default;
$font-weight-bold: 700 !default;
$font-weight-button-md: $font-weight-bold !default;
$font-weight-button-lg: $font-weight-bold !default;
$font-weight-heading-md: $font-weight-bold !default;
$font-weight-heading-lg: $font-weight-bold !default;
$font-weight-heading-xl: $font-weight-bold !default;
$font-weight-heading-2xl: $font-weight-bold !default;
$font-weight-heading-5xl: $font-weight-bold !default;
$font-weight-semibold: 600 !default;
$global__color: $color-base !default;
$global__color--inverse: $color-base-inverse !default;
$global__font-family: $font-family-body !default;
$global__font-size: $font-size-base !default;
$global__line-height: $font-line-height-base !default;
$accordion__color: $color-base !default;
$accordion__border-top-left-radius: 0px !default;
$accordion__border-top-right-radius: 0px !default;
$accordion__border-bottom-left-radius: 0px !default;
$accordion__border-bottom-right-radius: 0px !default;
$accordion__border-width: $spacer-half !default;
$accordion-button__color: $color-base !default;
$accordion-content__background-color: $color-white !default;
$accordion__background-color: $color-gray-lightest !default;
$accordion__background-color--hover: $color-gray-lighter !default;
$accordion__border-color: $color-gray-lightest !default;
$alert__background-color--lightweight: $color-white !default;
$alert__border-left-color: $color-info !default;
$alert__border-color--error: $color-error !default;
$alert__border-color--success: $color-success !default;
$alert__border-color--warn: $color-warn !default;
$alert__font-color: $color-base !default;
$alert__background-color: $color-info-lightest !default;
$alert__background-color--error: $color-error-lightest !default;
$alert__background-color--success: $color-success-lightest !default;
$alert__background-color--warn: $color-warn-lightest !default;
$alert__icon-size: 1.5rem !default;
$alert__padding: $spacer-2 !default;
$alert-bar__width: $spacer-1 !default;
$badge__background-color--alert: $color-error !default;
$badge__background-color--info: $color-primary !default;
$badge__background-color--success: $color-success !default;
$badge__background-color--warn: $color-warn !default;
$badge__font-color: $color-white !default;
$badge__font-color--alert: $color-white !default;
$badge__font-color--info: $color-white !default;
$badge__font-color--success: $color-white !default;
$badge__font-color--warn: $color-base !default;
$badge__background-color: $color-gray-dark !default;
$badge__border-radius: $radius-pill !default;
$button__background-color: $color-transparent !default;
$button__background-color--active: $color-gray-lighter !default;
$button__background-color--hover: $color-gray-lightest !default;
$button__background-color--disabled: $color-transparent !default;
$button__border-color: $color-primary !default;
$button__border-color--active: $color-primary-darkest !default;
$button__border-color--hover: $color-primary-darker !default;
$button__border-color--disabled: $color-gray-lighter !default;
$button__border-radius: $radius-default !default;
$button__border-width: 1px !default;
$button__color: $color-primary !default;
$button__color--active: $color-primary-darkest !default;
$button__color--hover: $color-primary-darker !default;
$button__color--disabled: $color-gray-lighter !default;
$button-alt__background-color: $color-transparent !default;
$button-alt__background-color--active: $color-gray-lighter !default;
$button-alt__background-color--hover: $color-gray-lightest !default;
$button-alt__background-color--disabled: $color-transparent !default;
$button-alt__border-color: $color-secondary !default;
$button-alt__border-color--active: $color-secondary-darkest !default;
$button-alt__border-color--hover: $color-secondary-darker !default;
$button-alt__border-color--disabled: $color-gray-lighter !default;
$button-alt__color: $color-secondary !default;
$button-alt__color--active: $color-secondary-darkest !default;
$button-alt__color--hover: $color-secondary-darker !default;
$button-alt__color--disabled: $color-gray-lighter !default;
$button-dark__background-color: $color-transparent !default;
$button-dark__background-color--active: $color-transparent !default;
$button-dark__background-color--hover: $color-transparent !default;
$button-dark__background-color--disabled: $color-transparent !default;
$button-dark__border-color: $color-gray-lightest !default;
$button-dark__border-color--active: $color-gray-lighter !default;
$button-dark__border-color--hover: $color-white !default;
$button-dark__border-color--disabled: $color-gray-dark !default;
$button-dark__color: $color-gray-lightest !default;
$button-dark__color--active: $color-gray-lighter !default;
$button-dark__color--hover: $color-white !default;
$button-dark__color--disabled: $color-gray-dark !default;
$button-dark-alt__background-color: $color-transparent !default;
$button-dark-alt__background-color--active: $color-transparent !default;
$button-dark-alt__background-color--hover: $color-transparent !default;
$button-dark-alt__background-color--disabled: $color-transparent !default;
$button-dark-alt__border-color: $color-secondary-lighter !default;
$button-dark-alt__border-color--active: $color-secondary-light !default;
$button-dark-alt__border-color--hover: $color-secondary-lightest !default;
$button-dark-alt__border-color--disabled: $color-gray-dark !default;
$button-dark-alt__color: $color-secondary-lighter !default;
$button-dark-alt__color--active: $color-secondary-light !default;
$button-dark-alt__color--hover: $color-secondary-lightest !default;
$button-dark-alt__color--disabled: $color-gray-dark !default;
$button-solid__background-color: $color-primary !default;
$button-solid__background-color--active: $color-primary-darkest !default;
$button-solid__background-color--hover: $color-primary-darker !default;
$button-solid__background-color--disabled: $color-gray-lighter !default;
$button-solid__border-color: $color-primary !default;
$button-solid__border-color--active: $color-primary-darkest !default;
$button-solid__border-color--hover: $color-primary-darker !default;
$button-solid__border-color--disabled: $color-gray-lighter !default;
$button-solid__color: $color-white !default;
$button-solid__color--active: $color-white !default;
$button-solid__color--hover: $color-white !default;
$button-solid__color--disabled: $color-gray-darker !default;
$button-solid-alt__background-color: $color-secondary !default;
$button-solid-alt__background-color--active: $color-secondary-darkest !default;
$button-solid-alt__background-color--hover: $color-secondary-darker !default;
$button-solid-alt__background-color--disabled: $color-gray-lighter !default;
$button-solid-alt__border-color: $color-secondary !default;
$button-solid-alt__border-color--active: $color-secondary-darkest !default;
$button-solid-alt__border-color--hover: $color-secondary-darker !default;
$button-solid-alt__border-color--disabled: $color-gray-lighter !default;
$button-solid-alt__color: $color-white !default;
$button-solid-alt__color--active: $color-white !default;
$button-solid-alt__color--hover: $color-white !default;
$button-solid-alt__color--disabled: $color-gray-darker !default;
$button-solid-dark__background-color: $color-gray-lightest !default;
$button-solid-dark__background-color--active: $color-gray-lighter !default;
$button-solid-dark__background-color--hover: $color-white !default;
$button-solid-dark__background-color--disabled: $color-gray-darker !default;
$button-solid-dark__border-color: $color-gray-lightest !default;
$button-solid-dark__border-color--active: $color-gray-lighter !default;
$button-solid-dark__border-color--hover: $color-white !default;
$button-solid-dark__border-color--disabled: $color-gray-darker !default;
$button-solid-dark__color: $color-base !default;
$button-solid-dark__color--active: $color-base !default;
$button-solid-dark__color--hover: $color-base !default;
$button-solid-dark__color--disabled: $color-base !default;
$button-solid-dark-alt__background-color: $color-secondary-lighter !default;
$button-solid-dark-alt__background-color--active: $color-secondary !default;
$button-solid-dark-alt__background-color--hover: $color-secondary-lightest !default;
$button-solid-dark-alt__background-color--disabled: $color-gray-darker !default;
$button-solid-dark-alt__border-color: $color-secondary-lighter !default;
$button-solid-dark-alt__border-color--active: $color-secondary !default;
$button-solid-dark-alt__border-color--hover: $color-secondary-lightest !default;
$button-solid-dark-alt__border-color--disabled: $color-gray-darker !default;
$button-solid-dark-alt__color: $color-base !default;
$button-solid-dark-alt__color--active: $color-base !default;
$button-solid-dark-alt__color--hover: $color-base !default;
$button-solid-dark-alt__color--disabled: $color-base !default;
$button-ghost__background-color: $color-transparent !default;
$button-ghost__background-color--active: $color-transparent !default;
$button-ghost__background-color--hover: $color-transparent !default;
$button-ghost__background-color--disabled: $color-transparent !default;
$button-ghost__border-color: $color-transparent !default;
$button-ghost__border-color--active: $color-transparent !default;
$button-ghost__border-color--hover: $color-transparent !default;
$button-ghost__border-color--disabled: $color-transparent !default;
$button-ghost__color: $color-primary !default;
$button-ghost__color--active: $color-primary-darkest !default;
$button-ghost__color--hover: $color-primary-darker !default;
$button-ghost__color--disabled: $color-gray-lighter !default;
$button-ghost-alt__background-color: $color-transparent !default;
$button-ghost-alt__background-color--active: $color-transparent !default;
$button-ghost-alt__background-color--hover: $color-transparent !default;
$button-ghost-alt__background-color--disabled: $color-transparent !default;
$button-ghost-alt__border-color: $color-transparent !default;
$button-ghost-alt__border-color--active: $color-transparent !default;
$button-ghost-alt__border-color--hover: $color-transparent !default;
$button-ghost-alt__border-color--disabled: $color-transparent !default;
$button-ghost-alt__color: $color-secondary !default;
$button-ghost-alt__color--active: $color-secondary-darkest !default;
$button-ghost-alt__color--hover: $color-secondary-darker !default;
$button-ghost-alt__color--disabled: $color-gray-lighter !default;
$button-ghost-dark__background-color: $color-transparent !default;
$button-ghost-dark__background-color--active: $color-transparent !default;
$button-ghost-dark__background-color--hover: $color-transparent !default;
$button-ghost-dark__background-color--disabled: $color-transparent !default;
$button-ghost-dark__border-color: $color-transparent !default;
$button-ghost-dark__border-color--active: $color-transparent !default;
$button-ghost-dark__border-color--hover: $color-transparent !default;
$button-ghost-dark__border-color--disabled: $color-transparent !default;
$button-ghost-dark__color: $color-gray-lightest !default;
$button-ghost-dark__color--active: $color-gray-lighter !default;
$button-ghost-dark__color--hover: $color-white !default;
$button-ghost-dark__color--disabled: $color-gray-dark !default;
$button-ghost-dark-alt__background-color: $color-transparent !default;
$button-ghost-dark-alt__background-color--active: $color-transparent !default;
$button-ghost-dark-alt__background-color--hover: $color-transparent !default;
$button-ghost-dark-alt__background-color--disabled: $color-transparent !default;
$button-ghost-dark-alt__border-color: $color-transparent !default;
$button-ghost-dark-alt__border-color--active: $color-transparent !default;
$button-ghost-dark-alt__border-color--hover: $color-transparent !default;
$button-ghost-dark-alt__border-color--disabled: $color-transparent !default;
$button-ghost-dark-alt__color: $color-secondary-lighter !default;
$button-ghost-dark-alt__color--active: $color-secondary !default;
$button-ghost-dark-alt__color--hover: $color-secondary-lightest !default;
$button-ghost-dark-alt__color--disabled: $color-gray-dark !default;
$choice__background-color: $color-background !default;
$choice__background-color--checked: $color-primary !default;
$choice__background-color--inverse: $color-transparent !default;
$choice__border-color: $color-base !default;
$choice__border-color--checked: $color-primary !default;
$choice__border-color--error: $color-base !default;
$choice__border-color--error--inverse: $color-white !default;
$choice__border-color--inverse: $color-white !default;
$choice__border-color--left: $color-primary !default;
$choice__border-radius: 0px !default;
$choice__border-width: 2px !default;
$choice__color--unchecked: $color-white !default;
$choice__color--disabled: $color-muted !default;
$choice__size--small: 20px !default;
$choice__size-radio: 22px !default;
$choice__size-radio--small: 12px !default;
$choice-label__color--disabled: $color-muted !default;
$choice-label__color--disabled--inverse: $color-muted-inverse !default;
$choice-label__top-offset: 0.25rem !default;
$choice__background-color--disabled: $color-gray-lighter !default;
$choice__border-color--disabled: $color-gray-light !default;
$choice__border-color--focus: $color-primary-darker !default;
$choice__border-color--disabled--inverse: $color-gray-light !default;
$choice__size: 32px !default;
$choice__background-color--disabled--inverse: #e9ecf126 !default;
$choice-wrapper__gap: 0.5rem !default;
$choice-wrapper__gap--small: 0.5rem !default;
$date-field__show-separators: 1 !default;
$day-picker-button__background-color--hover: $color-primary-lightest !default;
$dialog__background-color: $color-background !default;
$dialog-overlay__background-color: $color-transparent-black-alpha50 !default;
$dialog__padding: $spacer-4 !default;
$drawer__background-color: $color-background !default;
$drawer__border-color: $color-border !default;
$drawer-close__color: $color-black !default;
$drawer-toggle__background-color--hover: $color-info !default;
$drawer-toggle__background-color--hover--inverse: $color-white !default;
$drawer-toggle__color--hover: $color-white !default;
$drawer-toggle__color--hover--inverse: $color-gray-darker !default;
$drawer-header__background-color: $color-gray-lightest !default;
$drawer-footer__background-color: $color-info-lightest !default;
$drawer__animation-timing: $animation-speed-2 !default;
$filter-chip__background-color: $color-primary-lightest !default;
$filter-chip__background-color--active: $color-primary !default;
$filter-chip__border-color: $color-primary !default;
$filter-chip__border-color--active: $color-primary !default;
$filter-chip__border-radius: $radius-pill !default;
$filter-chip__color: $color-base !default;
$filter-chip__color--active: $color-white !default;
$filter-chip-icon__color: $color-base !default;
$filter-chip-icon__color-active: $color-white !default;
$hint__color: $color-muted !default;
$hint__color--inverse: $color-muted-inverse !default;
$label__font-family: $font-family-body !default;
$label__font-size: $font-size-md !default;
$label__font-weight: $font-weight-bold !default;
$label__letter-spacing: 0px !default;
$label__text-transform: inherit !default;
$inline-error__color: $color-error !default;
$inline-error__color--inverse: $color-error-lighter !default;
$link__color: $color-primary !default;
$link__color--active: $color-primary-darkest !default;
$link__color--hover: $color-primary-darker !default;
$link__color--visited: $color-visited !default;
$link__text-decoration-offset: 0.0390625rem !default;
$link__text-decoration-thickness: 0.0625rem !default;
$link__text-decoration-thickness--hover: 0.0625rem !default;
$link-inverse__color--active: $color-muted-inverse !default;
$link-inverse__color--hover: $color-muted-inverse !default;
$link-inverse__color--visited: $color-muted-inverse !default;
$link-inverse__color: $color-base-inverse !default;
$pagination-link__color: $color-primary !default;
$pagination-link__color--hover: $color-primary-darker !default;
$pagination-link__color--active: $color-primary-darkest !default;
$pagination-link__color--focus: $color-primary-darker !default;
$pagination-link__color--disabled: $color-gray-lighter !default;
$pagination-overflow__color: $color-gray-dark !default;
$pagination-page-count__color: $color-gray-dark !default;
$pagination-current-page__color: $color-base !default;
$steplist__background-color--current: $color-primary !default;
$steplist__color: $color-muted !default;
$steplist__color--current: $color-primary !default;
$steplist-step__border-color: $color-border !default;
$steplist-step__border-color--default: $color-muted !default;
$steplist-step__background-color--completed: $color-base !default;
$steplist-step__color: $color-muted !default;
$steplist-step__color--current: $color-white !default;
$steplist-step__color--completed: $color-base !default;
$review__border-color: $color-border !default;
$spinner__background-color: $color-background !default;
$spinner__color: $color-base !default;
$spinner__color--inverse: $color-white !default;
$spinner__background-color--inverse: $color-background-inverse !default;
$table__border-color: $color-black !default;
$table__background-color--striped: $color-gray-lightest !default;
$table__padding: $spacer-2 !default;
$table-header__background-color: $color-gray-lightest !default;
$tabs__border-color: $color-border !default;
$tabs__background-color: $color-background !default;
$tabs__background-color--selected: $color-background !default;
$tabs__background-color--hover: $color-background !default;
$tabs__background-color--disabled: $color-gray-lighter !default;
$tabs__border-color--selected: $color-primary !default;
$tabs__border-color--disabled: $color-gray-lighter !default;
$tabs__color: $color-base !default;
$tabs__color--hover: $color-primary !default;
$tabs__color--selected: $color-primary !default;
$tabs-panel__background-color: $color-background !default;
$tabs__color--active: $color-primary-darker !default;
$tabs__color--disabled: $color-gray-darker !default;
$text-input__background-color--disabled: $color-gray-lighter !default;
$text-input__border-width: 2px !default;
$text-input__border-width--disabled: 2px !default;
$text-input__border-width--error: 3px !default;
$text-input__border-color: $color-base !default;
$text-input__border-color--disabled: $color-gray-light !default;
$text-input__border-color--error: $color-error !default;
$text-input__border-color--error--inverse: $color-error-lighter !default;
$text-input__border-color--inverse: $color-black !default;
$text-input__border-radius: $radius-default !default;
$text-input__color: $color-base !default;
$text-input__divider-color: $color-gray-light !default;
$text-input__padding: $spacer-1 !default;
$text-input__line-height: 1.2999999523162842 !default;
$text-input-currency-icon__line-height: $text-input__line-height !default;
$text-input-currency-icon__spacing: 20px !default;
$tooltip__background-color: $color-background !default;
$tooltip__border-color: $color-gray-darker !default;
$tooltip__border-color--active: #0071bc40 !default;
$tooltip__border-color--inverse-active: #ffffff40 !default;
$tooltip__border-width: 1px !default;
$tooltip__box-shadow-color: $color-gray-light !default;
$tooltip__color: $color-base !default;
$tooltip-icon__color: $color-primary !default;
$tooltip-icon__color--inverse: $color-white !default;
$tooltip-trigger__color: $color-base !default;
$vertical-nav-item__background-color--hover: $color-gray-lightest !default;
$vertical-nav-item__border-color: $color-gray-dark !default;
$vertical-nav-item__color--hover: $color-primary !default;
$vertical-nav-label__border-color--current: $color-primary !default;
$vertical-nav-label__color: $color-base !default;
$vertical-nav-label__color--current: $color-primary !default;
$vertical-nav-label-icon__color: $color-base !default;
$usa-banner__background-color: $color-gray-lightest !default;
$usa-banner-domain-icon__color: $color-primary !default;
$usa-banner-close-button__color--mobile: $color-primary !default;
$usa-banner-close-button__background-color--mobile: $color-gray-lighter !default;
$usa-banner-security-icon__color: $color-success-light !default;